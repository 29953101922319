.team-logos{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: $content-gutter;
  overflow-y: scroll;
  max-height: 90vh;
  .heroPhotoElement{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 $element-gutter;
    margin: $element-gutter 0;
    width: 25%;
    flex-basis: 25%;
    a{
      max-height: 50px;
      height: 100%;
      color: $black;
    }
    img{
      max-width: 50px;
      width: 100%;
    }
    .cutline{
      background-color: transparent;
      padding: 0 10px;
      font-style: normal;
    }
  }
}
.leagues-title,
.district-title{
  width: 100%;
  display: none;
  // .network-open &{
  //   display: block;
  // }
}
.leagues{
  display: none;
  .league-open &{
    display: flex;
  }
}
.districts{
  display: none;
  .district-open &{
    display: flex;
  }
}

.network-container{
  position: relative;
  // display: none;
  .has-mobile-nav #topNav > & {
    display: none;
  }
  .has-main-nav .theme-mobile-nav & {
    display: none;
  }
}
.network-content{
  display: none;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  border: 1px solid $gray-lt;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  z-index: 9999;
  top: 0px;
  left: -$site-logo-size-w;
  transition: left $transition-linear;
  .nav-fixed &{
    left: -$site-logo-size-w-fixed;
  }
  .has-mobile-nav{
    top: $account-nav-height-mobile;
  }
}

.network-toggle{
  cursor: pointer;
  transition: background-color $transition-linear, color $transition-linear;
  .has-main-nav &{
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    background-color: #F1F2F2;
    padding: 0 15px;
    &:after{
      content: '\f107';
      display: inline-block;
      margin-left: 5px;
      font-family: FontAwesome;
      transition: color $transition-linear;
    }
    &:hover{
      background-color: $gray-lt;
    }
    &.opened{
      background-color: $primary-color;
      color: #FFF;
      &:after{
        content: '\f106';
        color: #FFF;
      }
    }

  }
  .has-mobile-nav &{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #ebeced;
    border-top: none;
    padding: 0;
    height: 40px;

    cursor: pointer;
    text-align: left;
    padding: 12px 0px 12px 20px;
    line-height: 1.25;
    color: #231f20;
    background: #FFF;
    transition: background $transition-linear;
    &:hover{
      background: #d1d3d4;
    }
    &:after{
      content: "";
      font-family: FontAwesome;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 10.625px;
      cursor: pointer;
      color: #231f20;
      // border-left: 1px solid #ebeced;
      width: 40px;
      height: 39px;
      margin-left: 20px;
    }
  }
}


.network-overlay {
  position: fixed;
  top: 35px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
  display: none;
  transition: opacity $transition-linear;
  .network-open &{
    opacity: .6;
    display: block;
  }
}
body.network-open{
  overflow: hidden;
}


// Edit Mode
.edit_mode .layoutContainer{
  &.district-members-logos,
  &.league-logos{
    .heroPhotoElement{
      max-width: 200px;
    }
  }
}
.user_mode{
  .district-members-logos,
  .league-logos{
    .column{
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .pageEl{
        width: 25%;
        flex-basis: 25%;
        @media screen and (max-width: 900px){
          width: 33%;
          flex-basis: 33%;
        }
        @media screen and (max-width: 545px){
          width: 50%;
          flex-basis: 50%;
        }
        @media screen and (max-width: 390px){
          width: 100%;
          flex-basis: 100%;
        }
      }
    }
    .heroPhotoElement{
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
    }
    a{
      max-height: 50px;
      height: 100%;
      color: $black;
    }
    img{
      max-width: 50px;
      width: 100%;
    }
    .cutline{
      background-color: transparent;
      padding: 0 10px;
      font-style: normal;
    }

  }
}

.theme-mobile-nav{
  .network-content{
    left: 0;
    width: 100%;
  }
  .network-overlay{
    top: 0;
    background-color: transparent;
  }
  .team-logos{min-height: 90vh;}
  .team-logos .heroPhotoElement{
    width: 100%;
    flex-basis: 100%;
  }
  .district-open & .district-title,
  .league-open & .leagues-title{
    display: flex;
  }
  .district-title,
  .leagues-title{
    width: 100%;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ddd;

    color: $slide-nav-title-color;
    font-family: $fontTwo;
    font-size: $slide-nav-font-size;
    font-weight: 400;
    line-height: 1.25;
  }
}
.network-close{
  font-family: FontAwesome;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 10.625px;
  cursor: pointer;
  transition: all 0.2s linear;
  color: #231f20;
  border-right: 1px solid #ebeced;
  background: transparent;
  width: 40px;
  height: 46px;
  margin-right: 20px;

  &:before{
    content: "";
  }
}
