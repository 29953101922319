//
.mobile-only{
  .has-main-nav .user_mode &{ display: none; }
}
.desktop-only{
  .has-mobile-nav .user_mode &{ display: none; }
}
.mobile-hero{
  display: flex;
  flex-flow: row;
  align-items: center;
  .user_mode &{
    height: 100vh;
    padding: 0px 10%;
    .column{
      max-width: 340px;
      margin: 0 auto;
      padding: 35px 20px;
    }
    .heroPhotoElement img{
      max-height: 60vh;
    }
  }
  .edit_mode & {
    .column{
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
  }
  .text h1{
    font-family: $fontTwo;
    font-weight: 400;
    text-transform: none;
    color: $primary-color;
    &:after{
      content: '\f107';
      display: inline-block;
      font-size: 20px;
      color: $primary-color;
      margin-top: 5px;
      font-family: FontAwesome;
      text-align: center;
      max-width: 100%;
      background-color: transparent;
      // transition: color $transition-linear;
    }
  }
  // .text{
  // }
}
.hero{
  position: relative;
  &:before{
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, .15);
    background-image: linear-gradient(to top, rgba(0,0,0,.7) 0, rgba(0,0,0,0) 25%);
  }

  .column{
    min-height: 400px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .text h1{
    font-family: $fontTwo;
    font-weight: 400;
    font-size: 72px;
    line-height: 1;
    color: #FFF;
    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.3);
    text-transform: none;

    &:after{
      margin: 10px auto 0 auto;
      height: 7px;
      max-width: 200px;
    }
  }
}

.card{

  // Tabbed Element
  .tabContainer{
    background-color: #FFF;
    border: 1px solid $global-line-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 0px solid transparent;
  }

  // Event Aggregator
  .eventAggregatorElement{
    @include card-style;
    padding-left: 0;
    padding-right: 0;
    .extendedOptions{
      padding-right: 15px;
    }
    .aggHeader{
      @include header-center;
    }
  }

}

@include page-element-break(330) {
  .card .eventAggregatorElement {
    padding-left: 20px;
    padding-right: 20px;
    .extendedOptions{
      padding-right: 0px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6,
    .col-md-3 {
      .card .eventAggregatorElement {
        padding-left: 0px;
        padding-right: 0px;
        .extendedOptions{
          padding-right: 15px;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6,
    .col-md-3 {
      .card .eventAggregatorElement {
        padding-left: 20px;
        padding-right: 20px;
        .extendedOptions{
          padding-right: 0px;
        }
      }
    }
  }
}


.textBlockElement{
  h1,
  h2,
  h3{
    &[style*="text-align:center"],
    &[style*="text-align: center"]{
      @include header-center;
    }
  }
}
.center-header .pageElement > h3{
  @include header-center;
}

@mixin cta-1{
  .column .pageEl{
    flex-basis: 100%;
    width: 100%;
    + .pageEl{
      border-top: 1px solid #000;
    }
  }
}
@mixin cta-2{
  .column .pageEl{
    flex-basis: 50%;
    width: 50%;
    &:nth-child(even) .textBlockElement{
      border-left: 1px solid #000;
    }
  }
}
@mixin cta-4{
  .column .pageEl{
    flex-basis: 25%;
    width: 25%;
    + .pageEl .textBlockElement{
      border-left: 1px solid #000;
    }
    &:nth-child(4n+1) .textBlockElement{
      border-left: none;
    }
  }
}

.cta-container{
  background-color: $black;
  .user_mode & {
    margin: -30px $column-gutter $element-gutter $column-gutter;
    @media screen and (max-width: 768px){
      margin-bottom: 10px;
    }
  }
  z-index: 5;
  position: relative;
  @media screen and (max-width: 529px){@include cta-1;}
  @media screen and (min-width: 530px) and (max-width: 767px){@include cta-2;}
  @media screen and (min-width: 768px){@include cta-4;}
  .column{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: $column-gutter;
  }
  .textBlockElement{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    .smallTextImage{
      float: none;
      margin: 0 auto;
      width: 55px !important;
      min-height: auto !important;
      margin-bottom: 12px;
      img{
        width: auto !important;
        height: 55px;
      }
    }
    .text{
      color: #FFF;
      h3{
        color: #FFF;
        font-size: 20px;
        margin-bottom: 15px;
        &:after{
          display: none;
        }
      }
      p{
        line-height: 1.4;
      }

    }
    .text p a{
      color: $white;
      padding: 13px 26px;
      background-color: $primary-color;
      transition: background-color $transition-linear;
      display: inline-block;
      border-radius: 5px;
      margin-top: 6px;
      &:hover{
        color: $white;
        background-color: $primary-color-dk;
      }
    }
  }
}


.user_mode{
  &.home{
    .theme-sub-nav li{
      display: none !important;
    }
  }
  .theme-main-nav .theme-nav-item.home,
  .theme-nav-forward.page_node_2958561{
    display: none !important;
  }
}


.tab-social-icons{
  .tab-remote{
    &:before{
      display: none;
      position: relative;
      font-family: FontAwesome;
      font-weight: 400;
      font-size: 20px;
      line-height: 1;
      @include font-smoothing;
    }
  }
  [data-tab-title*="twitter"]:before{
    content: "\f099";
  }
  [data-tab-title*="facebook"]:before{
    content: "\f09a";
  }
  [data-tab-title*="instagram"]:before{
    content: "\f16d";
  }
  [data-tab-title*="youtube"]:before{
    content: "\f167";
  }
  @media screen and (max-width: 410px){
    .tab-remote{
      &:before{ display: block; }
      span{ display: none; }
    }
  }
}
