.theme-search-modal .theme-search-box {
  background-color: transparent;
}

.theme-main-nav {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.theme-search-box {
  // display: flex;
  // flex-flow: row;
  // justify-content: flex-end;
}

#GoogleSearchBoxTopNav {
  .theme-search-input {
    display: none;
    outline: none;
    border: none;
    width: 100%;
    padding: 5px 10px;
    line-height: 17px;
    font-size: 14px;
    transition: padding $transition-linear, width $transition-linear;

    &:focus {
      padding: 5px 10px;
      width: 100%;
    }
  }

  .theme-search-submit {
    display: block;
    cursor: pointer;
    padding: 10px 20px;
    @include font-smoothing;

    &:before {
      content: "\f002";
      display: inline-block;
      // margin-left: 5px;
      font-family: FontAwesome;
      font-weight: 300;
      transition: color $transition-linear;
    }

    .search-title {
      display: none;
    }
  }
}

.has-mobile-nav{
  #GoogleSearchBoxTopNav {
    .theme-search-input,
    .theme-search-clear,
    .theme-search-submit,
    .search-title{
      display: none;
    }
  }
}
