.sponsor-container{
  width: 100%;
  background-color: $white;
  .sponsor-slider{
    display: flex !important;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
    padding: $element-gutter $column-gutter;
    .pageElement{
      // max-width: 100px;
      // width: 100%;
      // flex-basis: 100px;
      padding: $element-gutter $column-gutter;
      img{
        width: auto;
        max-height: 75px;
        max-width: 150px;
      }
    }
  }
}

// .sponsor-slider{
//   .pageElementAdmin{
//     img{
//       max-width: 100px;
//     }
//   }
// }
