/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

@mixin link-style($font-size){
  background-color: $primary-color;
  text-transform: uppercase;
  font-family: $fontTwo;
  font-size: $font-size;
  font-weight: 400;
  line-height: 1.1;
  border-radius: 3px;
  padding: 10px 5px;
  color: #FFF;
  text-decoration: none;
  transition: background-color $transition-standard;
  display: block;
  text-align: center;
  &:hover{
    text-decoration: none;
    background:$primary-color-dk;
  }
}

// 1. Page Element Styles
.linkElement{
  h4{
    a{
      @include link-style(14px);
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
  //   &:after{
  //     color: $accent-color;
  //     content: "\f061"; // Right Arrow
  //     font-family: FontAwesome;
  //     display:inline-block;
  //     line-height: .8em;
  //     margin:0 0 0 6px;
  //     padding:0;
  //     position: relative;
  //     transition: color .2s;
  //   }
  //   &:hover:after {
  //     color: #fff;
  //   }
  // }
  // .emailLink {
  //   &:after{
  //     content: "\f003"; // envelope-o
  //   }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}
