.input-group {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  input {
    margin-left: 35px;
  }
  .newsletter-legend{

  }
}


.newsletter-item{
  label{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 350px){
      flex-wrap: wrap;
      span{
        width: 100%;
      }
      [type="text"]{
        flex-grow: 1;
        width: auto;
      }
    }
    span{
      margin: 0 15px 0 0;
      flex-shrink: 0;
    }
    [title="required"]{
      margin: 0 0 0 15px;
    }
    [type="text"]{
      margin: 0;
      max-width: initial;
    }
  }
}
fieldset.newsletter-item{
  border: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  ul{
    list-style: none;
    margin: 5px 0 0 15px; 
  }
  label input{
    margin-right: 15px;
  }
}

.custom-newsletter-form{
  max-width: 500px;
  [title="required"]{
    color: $primary-color;
  }
  [type="submit"]{
    background-color: $primary-color;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.1;
    border-radius: 3px;
    padding: 10px 5px;
    color: #FFF;
    text-decoration: none;
    transition: background-color $transition-linear;
    display: inline-block;
    text-align: center;
    width: 100%;
    max-width: 100px;
    border-bottom: 0;
    margin-top: 15px;
    border: none;
    cursor: pointer;
    &:hover{
      background-color: $primary-color-dk;
    }
  }
}
