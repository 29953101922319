/* Event Aggregators */
// 1. Global Styles
// 2. List Views
// 3. List Views - Media Queries
// 4. Five Day View
// 5. Five Day View - Media Queries


$date-card-background: $primary-color;
$date-card-color: $white;
$date-card-date-size: 58px;
$date-card-month-size: 20px;
$date-card-width: 110px;
$card-content-margin: 20px;
$event-item-padding: 15px;

// 1. Global Styles
.eventAggregatorElement {
  .aggHeader {
    @include special-header;
  }
}

// 2. List Views
.eventAggregatorElement {
  .vevent {
    position: relative;
    padding: $event-item-padding 0;
    min-height: 100px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    // border-top: 1px solid $global-line-color;
    // border-bottom: 1px solid $global-line-color;
    // + .vevent {
    //   border-top: 0px solid $global-line-color;
    //   border-bottom: 1px solid $global-line-color;
    // }

    // ~ .vevent { @include line-pattern-border; }
    &.odd{
      background-color: $white;
      border-top: 1px solid $global-line-color;
      border-bottom: 1px solid $global-line-color;
    }
    &.even {background-color: $gray-lt; }
  }
  .summary,
  .details {
    list-style: none;
    padding-left: $date-card-width + $card-content-margin;
  }
  .summary a{
    font-size: 22px;
    font-family: $fontTwo;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-dk;
  }
  .time,
  .location {
    display: inline-block;
    margin-right: .5em;
    font-size: 14px;
    font-weight: 400;
  }
  .location {

    &, > a {
      // font-family: $fontOne;
      // font-weight: 600;
      color: $gray-dk;
      // text-transform: uppercase;
    }
  }
  .tags { font-size: 12px; }
  .description p{
    margin: 0;
  }
  .dateImage {
    display: flex;
    flex-flow: column;
    justify-content: center;
    color: $date-card-color;
    background: $date-card-background;
    padding: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    float: left;
    width: $date-card-width;
    box-sizing: border-box;
    list-style: none;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    .month {
      font-family: $fontTwo;
      font-size: $date-card-month-size;
      font-weight: 400;
      letter-spacing: .5px;
      @include font-smoothing;
    }
    .date {
      font-family: $fontOne;
      font-size: $date-card-date-size;
      font-weight: 900;
    }
  }
  .formElement {
    padding: 10px 0; // This should be better defined in global form styles (or removed from there to be styled per element)
    [id$="_start_date_day"],
    [id$="_end_date_day"] {
      width: 8em;
    }
  }
}

// 3. List Views - Media Queries
@include page-element-break(330) {
  .eventAggregatorElement {
    .vevent{
      &.odd,
      &.even{
        background-color: transparent;
        border: 0;
      }
    }
    .dateImage {
      float: none;
      margin-bottom: 1em;
      position: relative;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      width: 90px;
      .date{
        font-family: $fontTwo;
        font-size: $date-card-month-size;
        font-weight: 400;
        letter-spacing: .5px;
        @include font-smoothing;
        margin-left:5px;
      }
    }
    .summary,
    .details {
      padding-left: 0;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement {
        .dateImage {
          position: absolute;
          margin-bottom: 0;
        }
        .summary,
        .details {
          padding-left: $date-card-width + $card-content-margin;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement {
        .vevent{
          &.odd,
          &.even{
            background-color: transparent;
            border: 0;
          }
        }
        .dateImage {
          position: relative;
          margin-bottom: 1em;
          position: relative;
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;
          width: 90px;
          .date{
            font-family: $fontTwo;
            font-size: $date-card-month-size;
            font-weight: 400;
            letter-spacing: .5px;
            @include font-smoothing;
            margin-left:5px;
          }
        }
        .summary,
        .details {
          padding-left: 0;
        }
      }
    }
  }
}

// 4. Five Day View
.eventPreview {
  text-align: left;
  width: 100%;
  table-layout: fixed;
  th {
    @include table-th;
    @include table-th-text;
    word-break: break-word;
    a {
      color:#FFF;
      font-weight: 600;
    }
  }
  td {
    font-size: 12px;
    padding: 10px;
    background: #eee;
    vertical-align: top;
    word-break: break-word;
    &:before {
      content: attr(data-week-view-date)'';
      display: none;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 7px 12px;
      border-bottom: $table-cell-spacing solid #FFF;
      background: $link_color;
      color: #fff;
      text-transform: uppercase;
      font-family: $fontOne;
      font-size: 1em;
      line-height: 1.1;
      font-weight: 600;
    }
  }
  td,
  th {
    border: $table-cell-spacing solid #FFF;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
  .event + .event {
    padding-top: 10px;
  }
  .noentry {
    word-break:normal;
  }
}

// 5. Five Day View - Media Queries
@include page-element-break(430) {
  .eventPreview {
    border-top: $table-cell-spacing solid #FFF;
    &,
    tbody,
    tr,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    td {
      border: 0;
      padding: 0;
      margin-bottom: $table-cell-spacing;
      word-break: word-break;
      &:before {
        display: block;
      }
    }
    .event {
      padding: 10px;
      & + .event {
        padding-top: 0;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6 {
      .eventPreview {
        border-top: $table-cell-spacing solid #FFF;
        &,
        tbody,
        tr,
        td {
          display: block;
        }
        thead {
          display: none;
        }
        td {
          border: 0;
          padding: 0;
          margin-bottom: $table-cell-spacing;
          word-break: word-break;
          &:before {
            display: block;
          }
        }
        .event {
          padding: 10px;
          & + .event {
            padding-top: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 891px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-3 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          border: $table-cell-spacing solid #FFF;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
}
