.snFooterContainer{
  background-color: $gray-lt;
}
#siteFooter{
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0 $column-gutter;
  > ul{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0 $column-gutter ($content-gutter * 2);
    li{
      + li{ margin-left: 5px; }
    }
    #poweredByNGIN{
      a,
      img{
        display: block;
      }
      a{padding-top: 3px;}
    }
  }
}


// Custom Footer
#footer-extra{
  padding: $content-gutter 0;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  .footer-element{
    margin: $element-gutter 0;
    padding: 0 $column-gutter;
  }
  h3{
    font-family: $fontTwo;
    color: $primary-color;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .text{
    p{
      margin-bottom: 0;
      line-height: 2;
    }
    a{
      color: $gray-dk;
      border-bottom: 1px solid $gray-dk;
      transition: color $transition-linear, border $transition-linear;
      &:hover{
        color: $primary-color-dk;
        border-bottom: 1px solid $primary-color-dk;
      }
    }
  }
}

.footer-site-logo{
  width: 117px; // $site-logo-size-w;
  height: 192px; // $site-logo-size-h;
  box-sizing: content-box;

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

}

#footer-extra{
  .footer-social{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
  }
  .footer-newsletter{
    max-width: 380px;
    // flex-basis: 380px;
    .text p{
      line-height: 1.5;
    }
    a[href*="/newsletter"]{
      background-color: $primary-color;
      text-transform: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.1;
      border-radius: 3px;
      padding: 10px 5px;
      color: #FFF;
      text-decoration: none;
      transition: background-color $transition-linear;
      display: inline-block;
      text-align: center;
      width: 100%;
      max-width: 100px;
      border-bottom: 0;
      margin-top: 15px;
      &:hover{
        background-color: $primary-color-dk;
      }
    }
  }
}


#footer-extra{
  @media screen and (max-width: 767px){
    flex-flow: column;
    align-items: center;
    .footer-site-logo,
    .footer-links{
      display: none;
    }
    .footer-element{
      text-align: center;
      h3[style*="text-align: left"]{ text-align: center !important; }
    }
    .footer-social{
      justify-content: center;
    }
  }
  @media screen and (min-width: 768px){
    .footer-site-logo,
    .footer-links{
      flex-shrink: 0;
    }
  }
}
