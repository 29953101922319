// Code that is shared within multiple partials


// Rise — add to links to add a rising hover state
@mixin rise($color){
  position: relative;
  z-index: 0;
  &:after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    transform: scaleY(0);
    transform-origin: bottom left;
    transition: transform .1s linear;
    z-index: -1;
  }
  &:hover{
    &:after{
      transform: none;
    }
  }
}

@mixin zoom(){
  display: block;
  overflow: hidden;
  img{
    transform: scale(1);
    transition: transform $transition-linear;
    &:hover{
      transform: scale(1.1);
    }
  }
}



$sn-pageManager-width: 60px;
%standard-width{
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
}

%full-width{
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .page-manager-visible & {
      width: calc(100vw - #{$sn-pageManager-width});
      // margin-left: -30px;
  }
}

%full-width-image-background{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // margin-right: 0;
  &.layout-100{
    padding-left: 0;
    .column{
      padding-left: 0;
      padding-right: 0;
      float: none;
    }
  }
}
.full-width-container{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // @extend %full-width-image-background;
  // margin-right: 0;
  .fw-content{
    @extend %standard-width;
    padding: 0 15px;
  }

  // &.oneColumn{
  //   .pageElement{
  //     margin-right: 0;
  //   }
  // }
}
@mixin no-padding{
  .column{
    padding-left: 0;
    padding-right: 0;
  }
}


.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.background-cover-img{
  @extend %full-width-image-background;
}
.compact{
  &.pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  .pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  &-top{
    &.pageEl .pageElement{margin-top: 0;}
    .pageEl .pageElement{margin-top: 0;}
  }
  &-bottom{
    &.pageEl .pageElement{margin-bottom: 0;}
    .pageEl .pageElement{margin-bottom: 0;}
  }
}
@media screen and (max-width: 767px){
  .compact:not(.layout-100) .pageEl .pageElement{margin-bottom: 25px;}
  .compact:not(.layout-100) .column:last-of-type .pageEl:last-of-type .pageElement{
    margin-bottom: 0;
  }
}

.layoutContainer{
  &.padding{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.padding-top{
    padding-top: 40px;
  }
  &.padding-bottom{
    padding-bottom: 40px;
  }
}


.center-align{
  .fw-content, &.layoutContainer{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}



.maintain-ratio .video_aspect_ratio {
  padding-bottom: 0;
}
.textBlockElement > h3:only-child{
  margin-bottom: 0;
}

.element-header{
  .textBlockElement > h3 {
    @include special-header;
  }
}
