.codeElement{
  video{
    width: 100%;
    max-width: 100%;
  }
}
.custom-modal-open{
  overflow: hidden;
}
.custom-modal-overlay,
.custom-modal-container{
  display: none;
  .custom-modal-open &{
    display: block;
  }
}
.custom-modal-overlay{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.75);
  animation-name: fadeIn;
  animation-duration: 0.6s
}
.custom-modal-close{
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: inline-block;
  font-family: FontAwesome;
  font-size: 20px;
  line-height: 1;
  padding: 10px 12px;
  background-color: $primary-color;
  color: #FFF;
  @include font-smoothing;
  transition: background-color $transition-linear;
  &:after{
    content: "\f00d";
  }
  &:hover{
    background-color: $primary-color-dk;
  }
}
.custom-modal-container{
  z-index: 10001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  max-height: 50.75vw;
  // padding: 15px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.75);
  min-height: 50.75vw;
  background: #000;
}


@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}


.custom-modal-container{
  #videoSource{
    width: 100%;
    height: 100%;
    display: block;
    background-color: $black;
  }
}

@media screen and (min-aspect-ratio: 16/9){
  .custom-modal-container{
    width: 160vmin;
    min-height: auto;
  }
}

// @media all and (orientation: portrait) {
//   .custom-modal-container{
//     width: 160vmin;
//     min-height: auto;
//   }
// }
