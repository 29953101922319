#siteContainer {
  &, #siteContainer.container-fluid {
    padding: ($column-gutter*2 - $element-gutter) 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    // margin: ($site-logo-size-h - $site-tagline-height - $main-nav-height + 20px) auto 30px auto;
    background-color: #fff;
    // .has-sub-nav & {
    margin: ($site-logo-size-h - $site-tagline-height - $sub-nav-height - $main-nav-height + 20px) auto 30px auto;
    // }
    .home:not(.newsPage) & {
      background-color: transparent;
      margin-top: 0;
      padding-top: 0;
    }
    .has-mobile-nav &{
      margin-top: 15px;
    }
    .has-mobile-nav .home:not(.newsPage) &{
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    color: $page-title-color;
    padding: $element-gutter 0;
    margin: 0px ($column-gutter*2) $element-gutter ($column-gutter*2);
    font-size: $page-title-size;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: $borderWidth solid $borderColor;
  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}
