#header-extra{
  .header-membership{
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    flex-shrink: 0;
    padding: 0 10px;
    .smallTextImage{
      width: 20px !important;
      height: 20px;
      min-height: auto !important;
      margin: 0 0 0 10px;
      float: none;
      img{
        width: 20px !important;
        display: block;
      }
    }
    .text{
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
